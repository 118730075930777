import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn } from '@progress/kendo-react-grid'
import 'components/content-pool/_styles.scss'
import { TitleCell } from 'components/content-pool/kendocells'
import { DropdownComponent} from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Articles } from 'components/icons/articles'
import { RasaContext } from 'context'
import { DateRangeFilter, IndexName, SizeFilterDropdownOptions,
  SourceNameOptions, SuspectFilterDropdownOptions, toFilter } from 'elasticsearch/constants'
import { DateRangesAsDropdownOptions } from 'elasticsearch/constants'
import {
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Button } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import { ShareCell } from './share-cell'
import './styles.css'
import * as Utils from './utils'
import { ConnectedComponentClass } from 'react-redux'
import { ComponentType } from 'react'
import {Fields} from "../../shared/modals";
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { PagingSource, RasaDataGrid } from 'generic/rasaDataGrid'

export class ArticlesComponent extends RasaAnalyticsComponent<any, any> {

  constructor(props) {
    super(props)
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return(
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Articles'}
          description={['The articles your contacts are clicking on the most.']}
        />
        <div className="dropdown articles">
          <div className="grid-container">
            <DropdownComponent data={DateRangesAsDropdownOptions}
                                selected={this.state.selectedDateRange.key}
                                onChange={this.dateChanged}/>
            <DropdownComponent data={SuspectFilterDropdownOptions}
                                selected={this.state.selectedSuspectClick.key}
                                onChange={this.suspectedClickChanged}/>
          </div>
          <div className="grid-container">
            <div><span>Source Name</span>
              <DropdownComponent data={SourceNameOptions}
                                selected={this.state.selectedSourceName.key}
                                onChange={this.sourceClickChanged}/>
            </div>
            <div><span>Articles Count</span>
            <DropdownComponent data={SizeFilterDropdownOptions}
                                selected={this.state.selectedSize.key}
                                onChange={this.sizeChanged}/>
            </div>
          </div>
          <div className="date-range-picker">
              {this.state.selectedDateRange.key === '7' ?
              <DateRangePicker onChange={this.createCustomDate}
                               min={this.state.minCustomDateRange}
                               max ={new Date()} /> : null}
            </div>
        </div>
      {this.state.isFilterLoaded &&
        <AnalyticsArticlesTable dateRange={this.state.selectedDateRange.value}
              size={this.state.selectedSize.value} from={1} timezone={this.state.timezone}
              suspectClick={this.state.selectedSuspectClick.value} sourceName={this.state.selectedSourceName.value}/>}
    </div>
    )
  }

}

interface ArticleStats {
  opens?: number,
  post_id: string,
  realClicks?: number,
  realUniqueRate?: number,
  realUniqueCount?: number,
  sourceName?: string,
  suspectClick?: number,
  suspectUniqueRate?: number,
  suspectUniqueCount?: number,
  totalClicks?: number,
  totalUniqueRate?: number,
  totalUniqueCount?: number,
  uniqueClicks: number,
}

interface PostInfo {
  custom_tags?: string,
  nlp_tags?: string,
  source_tags?: string,
  post_id?: string,
  title?: string,
  url?: string,
}

type Article = ArticleStats & PostInfo

type Articles = Article[]

interface ArticlesState {
  loaded: boolean,
  communityGuid: string,
  shareUrl: string,
  shortenUrl: string,
  user: any,
  results: any[],
}

interface ArticlesProps extends ElasticsearchProps<Articles> {
  dateRange: any,
  offset: any,
  size: any,
  suspectClick?: any,
  sourceName?: any,
  timezone?: string,
}

class ArticlesTableComponent extends RasaReactComponent<ArticlesProps, ArticlesState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private xlsxExport: any = null
  constructor(p: ArticlesProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      isDirty: false,
      isLoading: false,
      isSaving: false,
      loaded: false,
      communityGuid: '',
      results: [],
      shareUrl: '',
      shortenUrl: '',
      user: {},
    }
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.config),
    ]).then(([activeCommunity, person, config]) => {
      this.setState({
        communityGuid: activeCommunity.data.community_guid,
        shortenUrl: config.shortenUrl,
        shareUrl: config.shareUrl,
        user: {
          email: person.email,
          firstName: person.firstName,
          lastName: person.lastName,
        },
      })
    })
  }

  public render = () => {
    const dateRangeFilter: DateRangeFilter = toFilter(this.props.dateRange)
    const displayClickType: any = Utils.getDisplayedClickType(this.props.suspectClick)
    const sourceTitle: any = this.props.sourceName === SourceNameOptions[0].value ?
    'Source' :
    'Publisher'
    return <div>
      <div className="articles-chart">
        
        <div className="articles">
        {this.state.results && this.state.results.length > 0 ?<Button
            disabled={this.state.results.length < 1}
            onClick={() => this.xlsxExport.save()}>
            Export xlsx
          </Button> : null}
          <ExcelExport data={this.state.results}
            fileName="RasaSourceReports.xlsx"
            ref={(exporter) => {this.xlsxExport = exporter}}>
            <RasaDataGrid
              // Rasa Properties for entity, datasetName etc
              entityName="articleDetails"
              datasetName="articleDetails"
              datasetParams={[
                { param: 'gte', value: encodeURIComponent(dateRangeFilter.gte) },
                { param: 'lt', value: encodeURIComponent(dateRangeFilter.lt) },
                { param: 'timezone', value: encodeURIComponent(this.props.timezone)},
                { param: 'excludeSuspectClick', value: Utils.isRealClickSelected(this.props.suspectClick) },
                { param: 'size', value: Number(this.props.size) || 100 },
                { param: 'sourceName', value: this.props.sourceName },
              ]}
              editDefaultState={false}
              // events
              onDataReceived={this.dataReceived}
              sortable={true}
              pagingSource={PagingSource.local}
              pageable={true}
              pageSize={50}
              data={[]}>
              <GridColumn field="sourceName" title={sourceTitle} width={250} />
              <GridColumn field="title" title="Title" cell={this.ClickableTitleCell} width={450}/>
              <GridColumn field="opens" title="Opens" width={120}/>

              <GridColumn field={displayClickType.field} title={displayClickType.title} width={120} />
              <GridColumn field="uniqueClicks" title="Unique Clicks" width={120}/>
              <GridColumn field="uniqueClickRate" title="Unique Click Rate" width={0}/>

              <GridColumn field="suspectClick" title="Total Suspect Clicks" width={0}/>
              <GridColumn field="suspectUniqueCount" title="Unique Suspect Clicks" width={0}/>
              <GridColumn field="suspectUniqueRate" title="Unique Suspect Rate" width={0}/>

              <GridColumn field="realClicks" title="Total Non-Suspect Clicks" width={0}/>
              <GridColumn field="realUniqueCount" title="Unique Non-Suspect Clicks" width={0}/>
              <GridColumn field="realUniqueRate" title="Unique Non-Suspect Rate" width={0}/>

              <GridColumn cell={ShareCell} field="url" title="Share" width={150}/>
            </RasaDataGrid>
          </ExcelExport>
        </div>
      </div>
    </div>
  }
  private dataReceived = (data) => {
		if (data.data) {
			this.setState({
				results: data.data,
			})
		}
	}
  private ClickableTitleCell = (props: any) => {
    return <TitleCell {...props} onClick={(e) => {this.openLink(e)}} />
  }
  private openLink = (p: any) => {
    window.open(p.url, '_blank')
  }
}

export const AnalyticsArticlesTable: ConnectedComponentClass<ComponentType<ArticlesTableComponent>, Fields> = GenericRedux.registerNewComponent(
  ArticlesTableComponent, 'home_articles', {})
