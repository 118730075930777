import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DropdownComponent} from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { AggregationType, DateRangeFilter, FilterType, IndexName,
  SuspectFilterDropdownOptions, toFilter } from 'elasticsearch/constants'
import { DateRangesAsDropdownOptions } from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Button } from 'reactstrap'
import { DEFAULT_COMMUNITY_PARTNER_CODES } from 'shared_server_client/constants'
import { formatDate } from 'shared_server_client/dates'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import * as Utils from './utils'
import { ConnectedComponentClass } from 'react-redux'
import { ComponentType } from 'react'
import {Fields} from "../../shared/modals";

export class ImagesClicksComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      selectedDateRange: DateRangesAsDropdownOptions[2],
      selectedSuspectClick: SuspectFilterDropdownOptions[0],
      selectedSegmentCode: DEFAULT_COMMUNITY_PARTNER_CODES.ALL,
    }
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Images'}
          description={[
            'The image URLS your contacts are clicking on via banner and square images in your newsletter',
          ]}
        />
        <div className="dropdown">
          <div className="grid-container">
            <DropdownComponent data={DateRangesAsDropdownOptions}
                              selected={this.state.selectedDateRange.key}
                              onChange={this.dateChanged}/>
            <DropdownComponent data={SuspectFilterDropdownOptions}
                              selected={this.state.selectedSuspectClick.key}
                              onChange={this.suspectedClickChanged}/>
          </div>
        </div>
        <div className="dropdown-segment-code">
        <LoadSegmentCodes segmentCode={this.state.selectedSegmentCode} setSegmentCode={this.segmentCodeChanged}
          hideLabel={true}></LoadSegmentCodes>
        </div>
        <div className="date-range-picker">
          {this.state.selectedDateRange.key === '7' ?
          <DateRangePicker min={this.state.minCustomDateRange}
                           max ={new Date()}
                           onChange={this.createCustomDate} /> : null}
        </div>
      {this.state.isFilterLoaded &&
        <AnalyticsImagesClickTable dateRange={this.state.selectedDateRange.value}
                timezone={this.state.timezone}
                selectedSegmentCode={this.state.selectedSegmentCode}
                suspectClick={this.state.selectedSuspectClick.value}/>}
      </div>
    )
  }

}

interface UrlStats {
  url: string,
  title?: string,
  totalClicks: number,
  uniqueClicks: number,
}

interface UrlInfo {
  url: string,
}

type Url = UrlStats & UrlInfo

type Urls = Url[]

interface ArticlesState {
  loaded: boolean,
}

interface UrlProps extends ElasticsearchProps<Urls> {
  dateRange: any,
  suspectClick?: any,
  selectedSegmentCode?: any,
  timezone?: string,
}

const PRIMARY_AGG = 'clicked_url'
const PRIMARY_AGG_LEGACY = 'clicked_url_1'
const TITLE_AGG = 'title'
const STATIC_IMAGE_AGG = 'static_image_id'

class ImagesClicksTableComponent extends ElasticsearchComponent<Urls, UrlProps, ArticlesState> {
  private xlsxExport: any = null
  constructor(p: UrlProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
    }
    this.reportName = Constants.REPORT_NAMES.IMAGES
  }

  public parseResponse(payload: ResponsePayload): Promise<any> {
    const clickedUrlAggregations = payload.aggregations[PRIMARY_AGG]
    const statsResponse = []
    const staticImageIds = []

    clickedUrlAggregations.buckets.map((aggregation: ResponseAggregate) =>  {
      const staticImageBuckets = aggregation[STATIC_IMAGE_AGG] && aggregation[STATIC_IMAGE_AGG].buckets &&
      aggregation[STATIC_IMAGE_AGG].buckets.length ? aggregation[STATIC_IMAGE_AGG].buckets : []
      const staticImageBucketIds = []
      if (staticImageBuckets.length) {
        staticImageBuckets.map((staticImageBucket: ResponseAggregate) => {
          const staticImageId = staticImageBucket.key ? Number(staticImageBucket.key) : 0
          if (staticImageId) {
            if (staticImageIds.indexOf(staticImageId) === -1) {
              staticImageIds.push(staticImageId)
            }
            staticImageBucketIds.push(staticImageId)
          }
        })
      } else {
        staticImageBucketIds.push(0)
      }
      staticImageBucketIds.map((staticImageId) => {
        if (!aggregation[TITLE_AGG] || !aggregation[TITLE_AGG].buckets || !aggregation[TITLE_AGG].length) {
          statsResponse.push({
            url: aggregation.key,
            title: '',
            totalClicks: aggregation.doc_count,
            uniqueClicks: aggregation.unique.value,
            realClicks: aggregation.doc_count,
            static_image_id: staticImageId,
          })
        } else {
          const titleResponse = aggregation.child.buckets.map((titleAggregation: ResponseAggregate) => {
            return ({
              url: aggregation.key,
              title: titleAggregation.key,
              totalClicks: titleAggregation.doc_count,
              uniqueClicks: titleAggregation.unique.value,
              realClicks: titleAggregation.doc_count,
              static_image_id: staticImageId,
            })
          })
          const titleTotalClicks = titleResponse.reduce((accumulator, agg) => {
            return accumulator + agg.totalClicks
          }, 0);
          const titleUniqueClicks = titleResponse.reduce((accumulator, agg) => {
            return accumulator + agg.uniqueClicks
          }, 0);
          if (aggregation.doc_count !== titleTotalClicks) {
            statsResponse.push({
              url: aggregation.key,
              title: '',
              totalClicks: aggregation.doc_count - titleTotalClicks,
              uniqueClicks: aggregation.unique.value - titleUniqueClicks,
              realClicks: aggregation.doc_count - titleTotalClicks,
              static_image_id: staticImageId,
          })
          }
          statsResponse.push(...titleResponse)
        }
      })
    })
    return this.getOpens(staticImageIds).then((result) => {
      const legacyAggregations = result.aggregations ? result.aggregations[PRIMARY_AGG_LEGACY] : {}
      const openAggregations = result.aggregations ? result.aggregations[PRIMARY_AGG] : {}

      const legacyBuckets = legacyAggregations.buckets ? legacyAggregations.buckets : []
      const openBuckets = (openAggregations.buckets ? openAggregations.buckets : []).concat(legacyBuckets)

      return this.getStaticImagesDetails(staticImageIds).then((staticImageDetails: any[]) => {
        this.setState({
          loaded: true,
        })
        return statsResponse.map((a) => {
          const displayClickType: any = Utils.getDisplayedClickType(this.props.suspectClick)
          const opensRecord = openBuckets.filter((x) => x.key === a.static_image_id)
          const opens = opensRecord.length ? opensRecord[0][Constants.DOC_COUNT] : ''
          const uniqueOpens = opensRecord.length ? opensRecord[0].unique.value : ''
          const staticImageInfo = this.staticImageInfoFor(a, staticImageDetails)
          const uniqueClickRate = uniqueOpens ? this.rateFormat((a.uniqueClicks / uniqueOpens) * 100) : ''
          const totalClickRate = opens ? this.rateFormat((a[displayClickType.field] / opens) * 100) : ''
          return ({
            ...a,
            opens,
            uniqueOpens,
            uniqueClickRate,
            totalClickRate,
            position: staticImageInfo.static_image_type ? staticImageInfo.static_image_type : '',
            title: staticImageInfo.title ? staticImageInfo.title : a.title,
            startDate: staticImageInfo.start_date ? formatDate(staticImageInfo.start_date) : '',
            endDate: staticImageInfo.end_date ? formatDate(staticImageInfo.end_date) : '',
          })
        })
      })
    })
   }

  public componentDidUpdate(oldProps: UrlProps) {
    if ( this.props.dateRange !== oldProps.dateRange || this.props.suspectClick !== oldProps.suspectClick
      || this.props.selectedSegmentCode !== oldProps.selectedSegmentCode ) {
      this.search()
    }
  }

  public searchPayload(): any {
    const dateRangeFilter: DateRangeFilter = toFilter(this.props.dateRange, this.props.timezone)
    if (dateRangeFilter.gte && dateRangeFilter.lt) {
      const search = bodybuilder().size(0)
        .filter(FilterType.range, 'message_send_date', dateRangeFilter)
        .filter(FilterType.term, 'event_name.keyword', 'click')
        .filter(FilterType.exists, 'static_image_id')
        .notFilter(FilterType.exists, 'post_subscription_id')
        .notFilter(FilterType.exists, 'community_promotion_id')
      if (this.props.suspectClick && Utils.isRealClickSelected(this.props.suspectClick)) {
        search.addFilter(FilterType.term, 'suspect_click', 0)
      }

      if (this.props.selectedSegmentCode === DEFAULT_COMMUNITY_PARTNER_CODES.NO_CODE) {
        search.notFilter(FilterType.exists, 'community_partner_code.keyword')
      } else if (this.props.selectedSegmentCode !== DEFAULT_COMMUNITY_PARTNER_CODES.ALL) {
        search.filter(FilterType.term, 'community_partner_code.keyword', this.props.selectedSegmentCode)
      }
      return this.addAggregation(search, {
        type: AggregationType.terms,
        field: 'normalized_click_url.keyword',
        name: PRIMARY_AGG,
        extra: { size: '100' },
        unique_on: 'community_person_id',
        childs: [{
          field: 'title.keyword',
          name: TITLE_AGG,
          type: AggregationType.terms,
          unique_on: 'community_person_id',
          extra: { size: '100' },
        }, {
          type: AggregationType.terms,
          field: STATIC_IMAGE_AGG,
          name: STATIC_IMAGE_AGG,
          unique_on: 'community_person_guid.keyword',
        }],
      }).build()
    }
  }

  public render = () => {
    const displayClickType: any = Utils.getDisplayedClickType(this.props.suspectClick)
    return <div>
      <div className="articles-chart">
        {this.state.loaded ?
        this.props.results && this.props.results.length > 0 ?
        <div className="other-clicks">
          <Button
            disabled={this.props.results.length < 1}
            onClick={() => this.xlsxExport.save()}>
            Export xlsx
          </Button>
          <ExcelExport data={this.props.results}
            fileName="RasaAdminReports.xlsx"
            ref={(exporter) => {this.xlsxExport = exporter}}>
            <Grid data={this.props.results} className="analytics-counts-grid">
              <GridColumn field="title" title="Title" width={200}/>
              <GridColumn field="position" title="Position" width={200}/>
              <GridColumn field="url" title="Url" width={500}/>
              <GridColumn field="startDate" title="Start Date" width={0}/>
              <GridColumn field="endDate" title="End Date" width={0}/>
              <GridColumn field="opens" title="Opens" width={150}/>
              <GridColumn field="uniqueOpens" title="Unique Opens" width={0}/>
              <GridColumn field={displayClickType.field} title={displayClickType.title} width={150}/>
              <GridColumn field="uniqueClicks" title="Unique Clicks" width={150}/>
              <GridColumn field="uniqueClickRate" title="Unique Click Rate" width={150}/>
              <GridColumn field="totalClickRate" title={`${displayClickType.title} Rate`} width={0}/>
            </Grid>
          </ExcelExport>
        </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
        </div> : <Loading size="64" />}
      </div>
    </div>
  }

  private rateFormat = (value: number) => {
    return `${value.toFixed(2)}%`
  }

  private staticImageInfoFor = (a: any, staticImages: any[]): any => {
    const hit = staticImages.find((p) => p.id === a.static_image_id)
    return hit ? hit : {}
  }

  private getOpens = (staticImageIds: string[]): Promise<any> => {
    if (staticImageIds.length) {
      const dateRangeFilter: DateRangeFilter = toFilter(this.props.dateRange, this.props.timezone)
      if (dateRangeFilter.gte && dateRangeFilter.lt) {

        const recordTypeQuery = (build) => {
          return build.filter(FilterType.term, 'record_type', 'image')
                      .filter(FilterType.terms, 'record_id', staticImageIds)
                      .filter(FilterType.term, 'status', 'open')
        }
        const matchIdsQuery = (build) => {
          return build.orQuery(FilterType.terms, 'static_image_id', staticImageIds)
              .orQuery('bool', recordTypeQuery)
              .queryMinimumShouldMatch(1)
        }
        const search = bodybuilder().size(0)
          .filter(FilterType.range, 'message_send_date', dateRangeFilter)
          .query('bool', matchIdsQuery)

        const payload = this.addAggregation(search, {
          type: AggregationType.terms,
          field: 'static_image_id',
          name: PRIMARY_AGG_LEGACY,
          extra: { size: staticImageIds.length.toString() },
          unique_on: 'community_person_id',
        })
        const payload2 = this.addAggregation(payload, {
          type: AggregationType.terms,
          field: 'record_id',
          name:  PRIMARY_AGG,
          extra: { size: staticImageIds.length.toString() },
          unique_on: 'community_person_id',
        })

        const query = payload2.build()
        return this.queryIndex(query, IndexName.MESSAGE_RUN_DETAIL)
      }
    }
    return Promise.resolve({})
  }

  private getStaticImagesDetails = (imageIds: string[]): Promise<any[]> => {
    if (imageIds.length) {
      const url: string = AjaxWrapper.getServerUrl() + `/dataset/${this.communityId}/staticImagesByIds`
      const payload = {
        imageIds: imageIds.join(','),
      }
      return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
      .then((response: any[]) => {
        return response[0]
      })
      .catch(() => ([]))
    } else {
      return Promise.resolve([])
    }
  }
}

export const AnalyticsImagesClickTable: ConnectedComponentClass<ComponentType<ImagesClicksTableComponent>, Fields> = GenericRedux.registerNewComponent(
  ImagesClicksTableComponent,
  'home_urls',
  {},
)
