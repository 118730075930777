import * as KendoChart from '@progress/kendo-react-charts'
import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import bodybuilder from 'bodybuilder'
import { DropdownComponent } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import {
  AggregateIntervalOptions,
  AggregateMetricOptions,
  AggregateSegmentationStrategyOptions,
  DateRangesAsDropdownOptions,
  dimensionsFiltersDropdownOptions, SuspectFilterDropdownOptionsForSegments
} from 'elasticsearch/constants'
import { AggregationType, DateRanges, FilterType, IndexName, toFilter } from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from "react-redux"
import {Fields} from "../../shared/modals";
import {ComponentType} from "react";
import { format } from 'date-fns'
import { OTHER_COLOR, CHART_COLORS, SEGMENTATION_AGGREGATION_BUCKET_SIZE } from './constants'
import { PieChart, PieChartData } from '../charts/pie'
import {
  FindIndexOfAggregateIntervalOptionByValue,
  FindIndexOfAggregateMetricOptionByValue,
  FindIndexOfAggregateSegmentationStrategyOptionByValue,
  FindIndexOfDateRangesOptionByValue,
  FindIndexOfDimensionsFiltersDropdownOptionByValue
} from '../../elasticsearch';
import * as Utils from './utils'

const defaultDateRange = FindIndexOfDateRangesOptionByValue(DateRanges.PastMonth)
const defaultInterval = FindIndexOfAggregateIntervalOptionByValue('week')
const defaultMetric = FindIndexOfAggregateMetricOptionByValue('click')
const defaultSegmentStrategyCode = FindIndexOfAggregateSegmentationStrategyOptionByValue('email_domain')
const defaultDimension = FindIndexOfDimensionsFiltersDropdownOptionByValue('5')
const defaultSuspect = FindIndexOfDimensionsFiltersDropdownOptionByValue('all_clicks')

const initialState = {
  selectedDateRange: DateRangesAsDropdownOptions[defaultDateRange],
  selectedInterval: AggregateIntervalOptions[defaultInterval],
  selectedMetric: AggregateMetricOptions[defaultMetric],
  selectedSegmentStrategyCode: AggregateSegmentationStrategyOptions[defaultSegmentStrategyCode],
  selectedDimension: dimensionsFiltersDropdownOptions[defaultDimension],
  selectedSuspectClick: SuspectFilterDropdownOptionsForSegments[defaultSuspect],
}

export class SegmentationComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Segmentation Report'}
        />
        <div className="dropdown-header">
          <div className="dropdown-inline">
            <DropdownComponent
              data={DateRangesAsDropdownOptions}
              selected={this.state.selectedDateRange.key}
              onChange={this.dateChanged}
            />
          </div>
          <div className="dropdown-inline">
            <DropdownComponent
              data={AggregateIntervalOptions}
              selected={this.state.selectedInterval.key}
              onChange={this.intervalChanged}
            />
          </div>
          <div className="dropdown-inline">
            <DropdownComponent
              data={dimensionsFiltersDropdownOptions}
              selected={this.state.selectedDimension.key}
              onChange={this.dimensionChanged}
            />
          </div>
        </div>
        <div className="dropdown-header">
          <div className="dropdown-inline">
            <DropdownComponent
              data={AggregateMetricOptions}
              selected={this.state.selectedMetric.key}
              onChange={this.metricChanged}
            />
          </div>
          <div className="dropdown-inline">
            <DropdownComponent
              data={AggregateSegmentationStrategyOptions}
              selected={this.state.selectedSegmentStrategyCode.key}
              onChange={this.segmentChanged}
            />
          </div>
          {this.state.selectedMetric.key === '1' && <div className="dropdown-inline">
            <DropdownComponent data={SuspectFilterDropdownOptionsForSegments}
                               selected={this.state.selectedSuspectClick.key}
                               onChange={this.suspectedClickChanged}/>
          </div>}
        </div>
        <div className="date-range-picker">
          {this.state.selectedDateRange.key === '7' ? (
            <DateRangePicker
              min={this.state.minCustomDateRange}
              max={new Date()}
              onChange={this.createCustomDate}
            />
          ) : null}
        </div>
        {this.state.isFilterLoaded &&
          <AnalyticsSegmentationComponent
            dateRange={this.state.selectedDateRange.value}
            selectedInterval={this.state.selectedInterval.value}
            selectedMetric={this.state.selectedMetric.value}
            selectedSegmentStrategyCode={
              this.state.selectedSegmentStrategyCode.value
            }
            selectedDimension={this.state.selectedDimension.value}
            suspectClick={this.state.selectedSuspectClick.value}
            timezone={this.state.timezone}
          />}
      </div>
    )
  }
}

interface LineChartPoint {
  timestamp: string
  [key: string]: number | string
}

interface PieChartState {
  color: string,
  category: string,
  totalRecords: number,
}

interface Points {
  [key: string]: any
}

interface EngagementProps extends ElasticsearchProps<Points> {
  dateRange: string
  selectedInterval: string
  selectedMetric: string
  selectedSegmentStrategyCode: string
  selectedDimension: number
  suspectClick?: any
  timezone?: string
}

interface EngagementState {
  loaded: boolean
  displayLabel: boolean
  topNthDomains: PieChartData[]
  gridLabel: string
  totalRecordsCount: number
  pieChartRecords: PieChartState[]
  lineChartRecords: LineChartPoint[],
  rawData: ResponsePayload,
}

const SEGMENTATION_AGGREGATION: string = 'segmentation'
const DATE_AGGREGATION: string = 'date'

class SegmentationClass extends ElasticsearchComponent<Points, EngagementProps, EngagementState> {
  constructor(p: EngagementProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      displayLabel: true,
      topNthDomains: [],
      gridLabel: '',
      totalRecordsCount: 0,
      pieChartRecords: [],
      lineChartRecords: [],
      rawData: {}
    }
    this.reportName = Constants.REPORT_NAMES.SEGMENTATION
  }

  public parseResponse(payload: ResponsePayload): Promise<Points> {
    this.setState({
      loaded: true,
    })

    this.setState({
      rawData: {...payload}
    }, () => this.needUpdatedChartData())

    return Promise.resolve([])
  }

  private needUpdatedChartData = () => {
    const emailDomainTotal = {}
    const data= this.state.rawData.aggregations[DATE_AGGREGATION].buckets
      .map((aggregation: ResponseAggregate) => {
        const partialData = {}
        partialData[aggregation.key] = aggregation.child.buckets
          .map((record: ResponseAggregate) => {
            emailDomainTotal[record.key] = emailDomainTotal[record.key] ?
              (emailDomainTotal[record.key] + record.doc_count) : record.doc_count
            return {
              emailDomain: record.key,
              count: record.doc_count
            }
          })

        return partialData
      })

    const topNthDomains: PieChartData[] = Object.entries(emailDomainTotal)
      .sort((a: any, b: any) => b[1] - a[1])
      .slice(0, this.props.selectedDimension)
      .map(([domainName, totalCount]: [string, number], index): PieChartData => {
        return {
          category: domainName,
          totalRecords: totalCount,
          color: CHART_COLORS[index]
        }
      })

    topNthDomains.push({
      category: 'other',
      color: OTHER_COLOR,
      totalRecords: 0,
    })

    let otherRecordCount = 0
    let totalRecordsCount = 0
    const lineChartRecords: LineChartPoint[] = []
    data.forEach((dt: any) => {
      const keyName = Object.keys(dt)[0]
      const emailData = dt[keyName]

      const partialData: LineChartPoint = {
        timestamp: format(new Date(parseInt(keyName, 10)), 'iii, MMM do'),
      }

      topNthDomains.forEach((domain: PieChartData) => {
        const customKeyName = this.customEmailDomainKey(domain.category)
        const found = emailData.find((ed: any) => ed.emailDomain === domain.category)
        const count = found ? found.count : 0
        partialData[customKeyName] = count
        totalRecordsCount += count
      })

      otherRecordCount += emailData.filter((r) => {
        const found = !topNthDomains.some((d: PieChartData) => d.category === r.emailDomain)
        partialData.other = !found ? 0 : r.count
        return found
      }).reduce((total, record) => {
        return total + record.count
      }, 0)

      lineChartRecords.push(partialData)
    })

    if (otherRecordCount > 0) {
      const otherIndex = topNthDomains.findIndex(x => x.category === 'other')
      topNthDomains[otherIndex] = {
        category: 'other',
        color: OTHER_COLOR,
        totalRecords: otherRecordCount,
      }
    } else {
      topNthDomains.pop()
    }

    this.setState({
      lineChartRecords,
      topNthDomains,
      totalRecordsCount: totalRecordsCount + otherRecordCount,
    })
  }

  private customEmailDomainKey = (emailDomain: string) => {
    return `${emailDomain.replace(/\./g, '_')}`
  }

  public componentDidUpdate(oldProps: EngagementProps) {
    if (
      this.props.selectedInterval !== oldProps.selectedInterval ||
      this.props.selectedSegmentStrategyCode !== oldProps.selectedSegmentStrategyCode ||
      this.props.selectedMetric !== oldProps.selectedMetric ||
      this.props.dateRange !== oldProps.dateRange ||
      this.props.suspectClick !== oldProps.suspectClick ||
      (this.props.selectedDimension !== oldProps.selectedDimension && !this.state.rawData.aggregations)
    ) {
      this.search()
      this.tooMuchData()
    }

    if (this.props.selectedDimension !== oldProps.selectedDimension && this.state.rawData.aggregations) {
      this.needUpdatedChartData()
    }

    if (
      this.props.selectedMetric !== oldProps.selectedMetric ||
      this.props.selectedDimension !== oldProps.selectedDimension || !this.state.gridLabel
    ) {
      this.setState({
        gridLabel:
          `Top ${this.props.selectedDimension} ${AggregateMetricOptions
            .find(x => x.value === this.props.selectedMetric).description}`
      })
    }
  }

  public searchPayload(): any {
    const search = bodybuilder()
      .size(0)
      .filter(
        FilterType.range,
        'message_send_date',
        toFilter(this.props.dateRange || DateRanges.PastMonth, this.props.timezone)
      )
      .filter(FilterType.term, 'event_name.keyword', this.props.selectedMetric)
       if (this.props.suspectClick && Utils.isRealClickSelected(this.props.suspectClick)) {
         search.addFilter(FilterType.term, 'suspect_click', 0)
       }
       if (this.props.suspectClick && Utils.isOnlySuspectClickSelected(this.props.suspectClick)) {
         search.addFilter(FilterType.term, 'suspect_click', 1)
       }

    return this.addAggregation(search, {
      type: AggregationType.date_histogram,
      field: 'message_send_date',
      extra: { interval: this.props.selectedInterval || 'day' },
      name: DATE_AGGREGATION,
      child: {
        type: AggregationType.terms,
        field: `${this.props.selectedSegmentStrategyCode}.keyword`,
        name: SEGMENTATION_AGGREGATION,
        extra: { size: SEGMENTATION_AGGREGATION_BUCKET_SIZE }
      },
    }).build()
  }

  public tooMuchData = () => {
    const display = (this.props.results && this.state.lineChartRecords.length < Constants.INTERVAL_THRESHOLD)
    this.setState({displayLabel: !display})
  }

  public render = () => <div className="analytics">
    <div className="engagement-chart">
      {this.state.lineChartRecords.length > 0 && this.state.topNthDomains ?
        <div>
          <PieChart field="totalRecords"
                    gridLabel={this.state.gridLabel}
                    totalCount={this.state.totalRecordsCount}
                    data={this.state.topNthDomains}
                    enableTooltip={false}
                    showDataGrid={true}/>
          <KendoChart.Chart transitions={false} pannable={false} zoomable={false}>
            <KendoChart.ChartLegend position="bottom" orientation="horizontal" />
            <KendoChart.ChartTooltip
              render={({points}: KendoChart.SharedTooltipContext) => {
                return <div className="engagement-tooltip">
                  <div>
                    {points[0].dataItem.timestamp || ''}
                  </div>
                  {
                    points.map((point) => {
                      return <div key={point.series.name + point.value}
                                  style={{color: point.dataItem[`${this.customEmailDomainKey(point.series.name)}_color`]}}>
                        {point.series.name}: {point.value}
                      </div>
                    })
                  }
                </div>
              }}
              shared={true}/>
            <KendoChart.ChartCategoryAxis>
              {this.state.lineChartRecords.length < Constants.INTERVAL_THRESHOLD &&
                <KendoChart.ChartCategoryAxisItem
                  categories={this.state.lineChartRecords.map((item) => item.timestamp)}/>}
            </KendoChart.ChartCategoryAxis>
            <KendoChart.ChartSeries>
              {this.state.topNthDomains
                .map((domain, index) => {
                  return (
                    <KendoChart.ChartSeriesItem
                      key={`line_chart_${index}`}
                      type="line"
                      field={this.customEmailDomainKey(domain.category)}
                      name={domain.category}
                      color={domain.color}
                      data={this.state.lineChartRecords}
                    >
                      <KendoChart.ChartSeriesLabels />
                    </KendoChart.ChartSeriesItem>
                  )
              })}

            </KendoChart.ChartSeries>
          </KendoChart.Chart>
        </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
      </div>}
      </div>
  </div>

}

export const AnalyticsSegmentationComponent: ConnectedComponentClass<ComponentType<SegmentationClass>, Fields> = GenericRedux.registerNewComponent(
  SegmentationClass, 'analytics_segmentation_report', {})
