import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { DropdownComponent } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import * as Validation from 'components/validation/validations'
import { DateRangesAsDropdownOptions, FilterType, IndexName, toFilter } from 'elasticsearch/constants'
import { ResponsePayload } from 'elasticsearch/types'
import { ElasticsearchComponent, ElasticsearchProps } from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import React, { ComponentType } from 'react'
import { Button } from 'reactstrap'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from 'react-redux'
import { Fields } from '../../shared/modals'

export class UrlRequestHistoryComponentClass extends RasaAnalyticsComponent<any, any> {
  public urlRequestHistoryTableRef;
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      selectedDateRange: DateRangesAsDropdownOptions.filter((x) => x.value ===
        this.getQuery(Constants.AnalyticsFilterKeys.SELECTED_DATE_RANGE, DateRangesAsDropdownOptions[4].value))[0],
      url: '',
    }
    this.urlRequestHistoryTableRef = React.createRef()
    this.invokeSearch = this.invokeSearch.bind(this)
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'Url Request History'}
          subTitle={'Request History'}
          description={[
            'Get history for your url.',
          ]}
        />
        <div className="dropdown">
          <DropdownComponent data={DateRangesAsDropdownOptions}
                              selected={this.state.selectedDateRange.key}
                              onChange={this.dateChanged}/>
        </div>
        <div className="date-range-picker">
        {this.state.selectedDateRange.key === '7' ?
        <DateRangePicker onChange={this.createCustomDate}
          min={this.state.minCustomDateRange} max ={new Date()} /> : null}
        </div>
        <div className="input">
          <input autoFocus disabled={this.state.isLoading} className="field-textbox url-input"
            placeholder="Enter the URL to get history"
            value={this.state.url}
            onChange={(e: any) => this.setState({
              url: e.target.value,
              isValidUrl: Validation.validateUrl(e.target.value).valid,
            })}
          />
          <span className="buttons">
            <Button disabled={!this.state.isValidUrl} onClick={this.invokeSearch} >Fetch</Button>
          </span>
        </div>
        {this.state.isFilterLoaded &&
        <UrlRequestHistotyTable
          ref={(urlRequestHistory) => {this.urlRequestHistoryTableRef = urlRequestHistory }}
          dateRange={this.state.selectedDateRange.value}
          timezone={this.state.timezone}
          url={this.state.url}/>}
      </div>
    )
  }
  private invokeSearch = () => {
    this.urlRequestHistoryTableRef.search()
  }
}

interface UrlRequest {
  domain: string,
  request_type : string,
  request_url: string,
  response_time : number,
  response_url : string,
  status_code: number,
  created: string,
}

type UrlRequests = UrlRequest[]

interface UrlRequestHistoryProps extends ElasticsearchProps<UrlRequests> {
  dateRange: any,
  url: any,
  timezone: string,
}

interface UrlRequestHistoryState {
  loaded: boolean,
}

class UrlRequestHistoryTableComponent extends ElasticsearchComponent<UrlRequests, UrlRequestHistoryProps, UrlRequestHistoryState> {
  private xlsxExport: any = null
  constructor(p: UrlRequestHistoryProps) {
    super(p, IndexName.URL_REQUESTS)
    this.state = {
      loaded: false,
    }
    this.reportName = Constants.REPORT_NAMES.URL_REQUESTS
  }

  public parseResponse(payload: ResponsePayload): Promise<UrlRequests> {
    this.setState({
      loaded: true,
    })
    return Promise.resolve(payload.hits.hits.map((hit) => hit._source as UrlRequest))
  }

  public searchPayload(): any {
    return this.generateSearchQuery().build()
  }

  public render = () => <div>
    <div className="topics-chart">
      {this.props.results && this.props.results.length > 0 ?
      <div>
        <Button
          disabled={this.props.results.length < 1}
          onClick={() => this.xlsxExport.save()}>
          Export xlsx
        </Button>
        <ExcelExport data={this.props.results}
          fileName="RasaAdminReports.xlsx"
          ref={(exporter) => {this.xlsxExport = exporter}}>
          <Grid data={this.props.results} className="analytics-counts-grid" scrollable="none">
            <GridColumn field="request_url" title="Url" />
            <GridColumn field="status_code" title="Status"/>
          </Grid>
        </ExcelExport>
      </div> :
      <div>
        <p className="no-data-tag">
          {Constants.NO_DATA_COPY}
        </p>
    </div>
      }
    </div>
  </div>

  private generateSearchQuery = (): bodybuilder.Bodybuilder => {
    const search = bodybuilder().size(100).filter(
      FilterType.range, 'created', toFilter(this.props.dateRange, this.props.timezone)
    ).filter(FilterType.term, 'request_url.keyword', this.props.url)

    return search
  }
}

export const UrlRequestHistotyTable: ConnectedComponentClass<ComponentType<UrlRequestHistoryTableComponent>, Fields> = GenericRedux.registerNewComponentWithRef(
  UrlRequestHistoryTableComponent, 'url_request_history_table', {})

export const UrlRequestHistoryComponent = GenericRedux.registerNewComponent(
  UrlRequestHistoryComponentClass,
  'url_request_history',
  {}
)
