import * as KendoChart from '@progress/kendo-react-charts'
import bodybuilder from 'bodybuilder'
import { HeaderComponent } from 'components/header/component'
import { addDays, format } from 'date-fns'
import {
  AggregateIntervalOptions,
  AggregationType,
  DateRanges, DateRangesAsDropdownOptions,
  FilterType,
  IndexName,
  toFilter
} from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from "react-redux"
import {Fields} from "../../shared/modals";
import {ComponentType} from "react";
import { Dataset } from '../../generic/dataset'
import { PROGRESS_STATUS } from './constants'
import { formatDateForES } from '../../shared_server_client/dates'

interface ProgressThreshold {
  excellent: number,
  good: number,
  needs_work: number,
  okay: number,
  poor: number,
  very_good: number
}

interface Benchmark {
  opens: ProgressThreshold,
  click_through: ProgressThreshold,
  hard_bounce: ProgressThreshold,
  soft_bounce: ProgressThreshold,
  spam_report: ProgressThreshold,
}

interface CommunityConfiguration {
  benchmark: Benchmark,
  score_individual: ProgressThreshold,
  score_global: ProgressThreshold,
}

interface Point {
  count: number,
  rate?: number
}

interface TypedPoint {
  unique: Point,
  total: Point,
}

interface Points {
  timestamp: string,
  click: TypedPoint,
  open: TypedPoint,
  delivered: TypedPoint,
  hardBounce: TypedPoint,
  softBounce: TypedPoint,
  spamReport: TypedPoint,
  dropped: TypedPoint,
}

interface EngagementProps extends ElasticsearchProps<Points> {
  dateRange: string,
  hideGrid: boolean,
  threshold: Benchmark,
  timezone: string,
}

interface EngagementState {
  loaded: boolean,
  displayLabel: boolean,
  points: Points[]
}

const INITIALIZED_BENCHMARK: Benchmark = {
  opens: {
    excellent: 40,
    good: 20,
    needs_work: 5,
    okay: 10,
    poor: 5,
    very_good: 30
  },
  click_through: {
    excellent: 3,
    good: 1.5,
    needs_work: 0.5,
    okay: 1,
    poor: 0.5,
    very_good: 2
  },
  hard_bounce: {
    excellent: 1,
    good: 2.5,
    needs_work: 4,
    okay: 3,
    poor: 4.5,
    very_good: 2
  },
  soft_bounce: {
    excellent: 1,
    good: 2.5,
    needs_work: 4,
    okay: 3,
    poor: 4.5,
    very_good: 2
  },
  spam_report: {
    excellent: 0.005,
    very_good: 0.01,
    good: 0.05,
    okay: 0.1,
    needs_work: 0.3,
    poor: 0.3,
  }
}

const INITIALIZED_SCORE_INDIVIDUAL: ProgressThreshold = {
  excellent: 10,
  very_good: 8,
  good: 6,
  okay: 4,
  needs_work: 2,
  poor: 0
}

const INITIALIZED_SCORE_GLOBAL: ProgressThreshold = {
  excellent: 10,
  very_good: 9,
  good: 8,
  okay: 6,
  needs_work: 4,
  poor: 2
}

const initialState = {
  benchmark: INITIALIZED_BENCHMARK,
  scoreIndividual: INITIALIZED_SCORE_INDIVIDUAL,
  scoreGlobal: INITIALIZED_SCORE_GLOBAL,
  selectedDateRange: DateRangesAsDropdownOptions[2],
}

const totalOpenColor = '#004f6d'
const uniqueBouncesColor = '#4ecdc4'
const uniqueSoftBouncesColor = '#ff6b6b'
const totalClickColor = '#8ae0e3'

const EVENT_AGGREGATION: string = 'event'
const DATE_AGGREGATION: string = 'date'

export class EmailHealthComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props, initialState)
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
    this.createCustomDate()
    this.context.user.init().then(({activeCommunity}) => {
      const params = [
        {param: 'key', value: 'email_health_report_threshold'}
      ]
      new Dataset().loadCommunityDataset('communityConfiguration', activeCommunity.communityId, params)
        .then((configuration) => {
          if (configuration.length > 0 && configuration[0].length > 0) {
            const config: CommunityConfiguration = JSON.parse(configuration[0][0].value)
            this.setState({
              benchmark: config.benchmark,
              scoreIndividual: config.score_individual,
              scoreGlobal: config.score_global,
            })
          }
        })
    })
  }

  public createCustomDate = () => {
    const endDate = new Date()
    const startDate = addDays(endDate, -32)

    const customRange = `${formatDateForES(startDate)}|${formatDateForES(addDays(endDate, 1))}`

    this.setState({
      selectedDateRange: {
        ...this.state.selectedDateRange,
        value: customRange,
      },
    })
  }

  public render() {
    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Email Health History'}
          description={[
            'Opens - The number of times your newsletter has been opened by your subscribers.',
            'Clicks - The number of times any of your subscribers have clicked on a link in your newsletter.',
            'Click to Open - the number of clicks out of the number of opens',
          ]}
        />
        {this.state.isFilterLoaded &&
        <AnalyticsOpensClicksComponent dateRange={this.state.selectedDateRange.value}
                                       threshold={this.state.benchmark} timezone={this.state.timezone}/>}
      </div>
    )
  }
}


class OpensClicksClass extends ElasticsearchComponent<Points, EngagementProps, EngagementState> {

  constructor(p: EngagementProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      displayLabel: true,
      points: []
    }
    this.reportName = Constants.REPORT_NAMES.EMAIL_HEALTH
  }

  public parseResponse(payload: ResponsePayload): Promise<Points> {
    this.setState({
      loaded: true,
    })
    const dateAggregationsBuckets = payload.aggregations[DATE_AGGREGATION].buckets
      .filter((aggregation: ResponseAggregate) => {
        return this.getAggregation(aggregation.child, 'delivered')
      })

    return Promise.resolve(dateAggregationsBuckets.map((aggregation: ResponseAggregate) => {
      const d = new Date(new Date(aggregation.key))
      const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
      const delivered = this.toTypedPoint(this.getAggregation(aggregation.child, 'delivered'), 0)
      const totalDelivered = delivered.total.count
      const points: Points = {
        delivered,
        timestamp: format(new Date(dateWithoutOffset), 'iii, MMM do'),
        click: this.toTypedPoint(this.getAggregation(aggregation.child, 'click'), totalDelivered),
        open: this.toTypedPoint(this.getAggregation(aggregation.child, 'open'), totalDelivered),
        hardBounce: this.toTypedPoint(this.getAggregation(aggregation.child, 'hard_bounce'), totalDelivered),
        softBounce: this.toTypedPoint(this.getAggregation(aggregation.child, 'soft_bounce'), totalDelivered),
        spamReport: this.toTypedPoint(this.getAggregation(aggregation.child, 'spamreport'), totalDelivered),
        dropped: this.toTypedPoint(this.getAggregation(aggregation.child, 'dropped'), totalDelivered),
      }
      return points

    })).then((points: Points[]) => {
      const totalDelivered = points.reduce((acc, point) => acc + point.delivered.total.count, 0)
      this.setState({
        points,
      })

      const aggregatedData: Points = {
        timestamp: points.length ? points[0].timestamp : '',
        click: this.addRate(points, 'click', totalDelivered),
        open: this.addRate(points, 'open', totalDelivered),
        hardBounce: this.addRate(points, 'hardBounce', totalDelivered),
        softBounce: this.addRate(points, 'softBounce', totalDelivered),
        spamReport: this.addRate(points, 'spamReport', totalDelivered),
        dropped: this.addRate(points, 'dropped', totalDelivered),
        delivered: {
          total: {
            count: totalDelivered,
          },
          unique: {
            count: totalDelivered
          }
        }
      }
      return aggregatedData
    })
  }

  public searchPayload(): any {
    const search = bodybuilder().size(0)
      .filter(FilterType.range, 'message_send_date', toFilter(this.props.dateRange || DateRanges.PastMonth, this.props.timezone))

    return this.addAggregation(search, {
      type: AggregationType.date_histogram,
      field: 'message_send_date',
      extra: {interval: AggregateIntervalOptions[0].value},
      name: DATE_AGGREGATION,
      child: {
        type: AggregationType.terms,
        field: 'event_name.keyword',
        name: EVENT_AGGREGATION,
        unique_on: 'community_person_id',
      },
    }).build()
  }

  public render = () => {

    if ( !this.props.results || !this.state.points || this.state.points.length === 0 ) {
      return <div>
        <p className="no-data-tag">{Constants.NO_DATA_COPY}</p>
      </div>
    }

    const showChartLabels = (this.state.points && this.state.points.length < Constants.INTERVAL_THRESHOLD)

    const clickRate: number = this.props.results.click.unique.rate
    const openRate: number = this.props.results.open.unique.rate
    const hardBounceRate: number = this.props.results.hardBounce.unique.rate
    const softBounceRate: number = this.props.results.softBounce.unique.rate
    const spamReportRate: number = this.props.results.spamReport.unique.rate

    const totalDelivered = this.props.results.delivered.total.count

    const clickThroughTags = this.opensAndClicksTag(clickRate, this.props.threshold.click_through)
    const openTags         = this.opensAndClicksTag(openRate, this.props.threshold.opens)
    const hardBounceTags   = this.bounceTag(hardBounceRate, this.props.threshold.hard_bounce)
    const softBounceTags   = this.bounceTag(softBounceRate, this.props.threshold.soft_bounce)
    const spamReportTags   = this.bounceTag(spamReportRate, this.props.threshold.spam_report)

    const score = this.calculateScore()
    const rotation = ((score / 10) * 180) - 180

    return (
      <>
          <div className='statistics-top-wrapper'>
            <div className="statistic-main-wrap">
              <div className='statistics-chart d-flex flex-column align-items-center'>
                <strong className='statistics-chart-title'>YOUR SCORE</strong>
                <div className={`statistics-chart-round ${this.categorizeScore(score).class}`}
                     style={{'--rotation': `${rotation}deg`} as { [key: string]: string }}>
                  <span className="overlay"></span>
                </div>
                <strong className='statistics-chart-right'>{score}</strong>
                <strong className='statistics-chart-subtitle'>{this.categorizeScore(score).status}</strong>
                <strong className='statistics-chart-subtext'>{this.scoreDate()}</strong>
              </div>
              <div className="statistic-summary">
                <div className="summary-description">
                  <strong>Summary</strong>
                  <span>Score over time</span>
                </div>
                <div className="metrics-description">
                  <strong>Metrics</strong>
                  <ul>
                    <li>
                      <span>Opens</span>
                      <div className={`stw-tag-wrapper ${openTags.class}`}>
                        {openTags.title}
                      </div>
                    </li>
                    <li>
                      <span>Click-Through</span>
                      <div className={`stw-tag-wrapper ${clickThroughTags.class}`}>
                        {clickThroughTags.title}
                      </div>
                    </li>
                    <li>
                      <span>Hard bounces</span>
                      <div className={`stw-tag-wrapper ${hardBounceTags.class}`}>
                        {hardBounceTags.title}
                      </div>
                    </li>
                    <li>
                      <span>Soft Bounce</span>
                      <div className={`stw-tag-wrapper ${softBounceTags.class}`}>
                        {softBounceTags.title}</div>
                    </li>
                    <li>
                      <span>Spam Reports</span>
                      <div className={`stw-tag-wrapper ${spamReportTags.class}`}>
                        {spamReportTags.title}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="list-percent-wrap">
              <ul className='list-unstyled d-flex stw-list text-center'>
                <ProgressComponent
                  title={'OPENS'}
                  heading={`${openRate.toFixed(2)}%`}
                  tagTitle={openTags.title}
                  tagSubtitle={`${openTags.subtitle}%`}
                  tagClass={openTags.class}/>
                <ProgressComponent
                  title={'CLICK THROUGH'}
                  heading={`${clickRate.toFixed(2)}%`}
                  tagTitle={clickThroughTags.title}
                  tagSubtitle={`${clickThroughTags.subtitle}%`}
                  tagClass={clickThroughTags.class}/>
                <ProgressComponent
                  title={'HARD BOUNCES'}
                  heading={`${hardBounceRate.toFixed(2)}%`}
                  tagTitle={hardBounceTags.title}
                  tagSubtitle={`${hardBounceTags.subtitle}%`}
                  tagClass={hardBounceTags.class}/>
                <ProgressComponent
                  title={'SOFT BOUNCE'}
                  heading={`${softBounceRate.toFixed(2)}%`}
                  tagTitle={softBounceTags.title}
                  tagSubtitle={`${softBounceTags.subtitle}%`}
                  tagClass={softBounceTags.class}/>
                <ProgressComponent
                  title={'SPAM REPORTS'}
                  heading={`${spamReportRate.toFixed(2)}%`}
                  tagTitle={spamReportTags.title}
                  tagSubtitle={`${spamReportTags.subtitle}%`}
                  tagClass={spamReportTags.class}/>
                <li className='stw-list-item'>
                  <div className='stw-column'>
                    <h2 className='stw-title'>EMAILS SENT</h2>
                    <h3 className='stw-heading'>{totalDelivered}</h3>
                  </div>
                </li>
              </ul>
              <div className="analytics">
                <div className="engagement-chart">
                  <div>
                    <KendoChart.Chart transitions={false} pannable={false} zoomable={false}>
                      <KendoChart.ChartLegend position="bottom" orientation="horizontal"/>
                      <KendoChart.ChartTooltip
                        render={({points}: KendoChart.SharedTooltipContext) => (
                          <div className="engagement-tooltip">
                            <div>{this.props.results.timestamp || ''}</div>
                            {points.map((point) => (
                              <div key={point.series.name + point.value} style={{color: point.series.color}}>
                                {point.series.name}: {point.value.toFixed(2)}%
                              </div>
                            ))}
                          </div>
                        )}
                        shared={true}/>
                      <KendoChart.ChartCategoryAxis>
                        { showChartLabels &&
                          <KendoChart.ChartCategoryAxisItem
                            categories={this.state.points.map((point: Points) => point.timestamp)}/>
                        }
                      </KendoChart.ChartCategoryAxis>
                      <KendoChart.ChartSeries>
                        <KendoChart.ChartSeriesItem
                          type="line"
                          color={totalOpenColor}
                          name="Total Open"
                          data={this.state.points.map((point: Points) => point.open.unique.rate)}
                        >
                        { showChartLabels && <KendoChart.ChartSeriesLabels format="{0:N2}%"/> }
                        </KendoChart.ChartSeriesItem>
                        <KendoChart.ChartSeriesItem
                          type="line"
                          color={uniqueBouncesColor}
                          name="Total Unique Bounces"
                          data={this.state.points.map((point: Points) => point.hardBounce.unique.rate)}
                        >
                        { showChartLabels && <KendoChart.ChartSeriesLabels format="{0:N2}%"/> }
                        </KendoChart.ChartSeriesItem>
                        <KendoChart.ChartSeriesItem
                          type="line"
                          color={uniqueSoftBouncesColor}
                          name="Total Unique Soft Bounces"
                          data={this.state.points.map((point: Points) => point.softBounce.unique.rate)}
                        >
                        { showChartLabels && <KendoChart.ChartSeriesLabels format="{0:N2}%"/> }
                        </KendoChart.ChartSeriesItem>
                        <KendoChart.ChartSeriesItem
                          type="line"
                          color={totalClickColor}
                          name="Total Click"
                          data={this.state.points.map((point: Points) => point.click.unique.rate)}
                        >
                        { showChartLabels && <KendoChart.ChartSeriesLabels format="{0:N2}%"/> }
                        </KendoChart.ChartSeriesItem>
                      </KendoChart.ChartSeries>
                    </KendoChart.Chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </>
    )
  }


  private toTypedPoint(aggregation: ResponseAggregate, deliveredCount: number): TypedPoint {
    const point: TypedPoint = {
      total: {
        count: aggregation ? aggregation.doc_count : 0,
      },
      unique: {
        count: ( aggregation && aggregation.unique ) ? aggregation.unique.value : 0,
      }
    }
    if ( deliveredCount > 0 ) {
      point.total.rate = point.total.count / deliveredCount * 100
      point.unique.rate = point.unique.count / deliveredCount * 100
    }
    return point
  }

  private addRate(dataArray: Points[], key: string, totalDelivered: number): TypedPoint {
    const aggregate: TypedPoint = {
      total: {
        count: dataArray.reduce((acc, point) => acc + point[key].total.count, 0),
      },
      unique: {
        count: dataArray.reduce((acc, point) => acc + point[key].unique.count, 0),
      }
    }
    aggregate.total.rate = aggregate.total.count / totalDelivered * 100
    aggregate.unique.rate = aggregate.unique.count / totalDelivered * 100
    return aggregate
  }

  private scoreDate = () => {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit'
    };
    const endDate = new Date()
    const startDate = addDays(endDate, -30)
    const formattedEndDate = endDate.toLocaleDateString('en-US', options);
    const formattedStartDate = startDate.toLocaleDateString('en-US', options);

    return `${formattedStartDate} - ${formattedEndDate}`
  }
  private bounceTag = (number: number, rate: ProgressThreshold) => {
    if (!rate) {
      return {
        title: PROGRESS_STATUS.POOR,
        subtitle: rate.poor,
        class: 'red'
      };
    }

    const thresholds = [
      {limit: rate.excellent, status: PROGRESS_STATUS.EXCELLENT},
      {limit: rate.very_good, status: PROGRESS_STATUS.VERY_GOOD},
      {limit: rate.good, status: PROGRESS_STATUS.GOOD},
      {limit: rate.okay, status: PROGRESS_STATUS.OKAY},
      {limit: rate.needs_work, status: PROGRESS_STATUS.NEEDS_WORK}
    ];

    const result = thresholds.find(({limit}) => number < limit);

    if (result) {
      const isYellow = [PROGRESS_STATUS.NEEDS_WORK, PROGRESS_STATUS.OKAY].includes(result.status)
      return {
        title: result.status,
        subtitle: result.limit,
        class:  isYellow ? 'yellow' : 'green'
      };
    }

    return {
      title: PROGRESS_STATUS.POOR,
      subtitle: rate.poor,
      class: 'red'
    };
  }

  private calculateScore = () => {
    if (this.props.results) {
      const {results} = this.props
      const opensScore = this.getMetricScore(results.open.unique.rate, this.props.threshold.opens, Constants.MetricScoreOperation.GREATER)
      const clicksScore = this.getMetricScore(results.click.unique.rate, this.props.threshold.click_through, Constants.MetricScoreOperation.GREATER)
      const hardBouncesScore = this.getMetricScore(results.hardBounce.unique.rate, this.props.threshold.hard_bounce, Constants.MetricScoreOperation.LESS)
      const uniqueSoftBouncesScore = this.getMetricScore(results.softBounce.unique.rate, this.props.threshold.soft_bounce, Constants.MetricScoreOperation.LESS)
      const spamReportScore = this.getMetricScore(results.spamReport.unique.rate, this.props.threshold.spam_report, Constants.MetricScoreOperation.LESS)

      const scoreSum = opensScore + clicksScore + hardBouncesScore + uniqueSoftBouncesScore + spamReportScore
      const averageScore = scoreSum / 5
      if (opensScore === 0 || clicksScore === 0 || hardBouncesScore === 0 || uniqueSoftBouncesScore === 0 || spamReportScore === 0) {
        return Math.min(averageScore, 6)
      }

      return Number(averageScore.toFixed(1))
    }
    return 1
  }

  private categorizeScore = (score: number) => {
    if (score <= INITIALIZED_SCORE_GLOBAL.poor) {
      return {
        status: PROGRESS_STATUS.POOR,
        class: 'red'
      }
    } else if (score <= INITIALIZED_SCORE_GLOBAL.needs_work) {
      return {
        status: PROGRESS_STATUS.NEEDS_WORK,
        class: 'yellow'
      }
    } else if (score <= INITIALIZED_SCORE_GLOBAL.okay) {
      return {
        status: PROGRESS_STATUS.OKAY,
        class: 'yellow'
      }
    } else if (score <= INITIALIZED_SCORE_GLOBAL.good) {
      return {
        status: PROGRESS_STATUS.GOOD,
        class: 'green'
      }
    } else if (score <= INITIALIZED_SCORE_GLOBAL.very_good) {
      return {
        status: PROGRESS_STATUS.VERY_GOOD,
        class: 'green'
      }
    } else {
      return {
        status: PROGRESS_STATUS.EXCELLENT,
        class: 'green'
      }
    }
  }

  private getMetricScore = (
    metricValue: number,
    threshold: ProgressThreshold,
    comparisonOperator: Constants.MetricScoreOperation
  ) => {
    const thresholds = [
      {min: threshold.excellent, score: INITIALIZED_SCORE_INDIVIDUAL.excellent},
      {min: threshold.very_good, score: INITIALIZED_SCORE_INDIVIDUAL.very_good},
      {min: threshold.good, score: INITIALIZED_SCORE_INDIVIDUAL.good},
      {min: threshold.okay, score: INITIALIZED_SCORE_INDIVIDUAL.okay},
      {min: threshold.needs_work, score: INITIALIZED_SCORE_INDIVIDUAL.needs_work},
      {min: threshold.poor, score: INITIALIZED_SCORE_INDIVIDUAL.poor}
    ]
    let result = thresholds.find(({min}) => metricValue <= min)

    if(comparisonOperator === Constants.MetricScoreOperation.GREATER) {
       result = thresholds.find(({min}) => metricValue >= min)
    }

    return result ? result.score : 0
  }

  private opensAndClicksTag = (number: number, rate: ProgressThreshold) => {
    const thresholds = [
      {status: PROGRESS_STATUS.EXCELLENT, value: rate.excellent},
      {status: PROGRESS_STATUS.VERY_GOOD, value: rate.very_good},
      {status: PROGRESS_STATUS.GOOD, value: rate.good},
      {status: PROGRESS_STATUS.OKAY, value: rate.okay},
      {status: PROGRESS_STATUS.NEEDS_WORK, value: rate.needs_work},
      {status: PROGRESS_STATUS.POOR, value: rate.poor}
    ];

    if (!rate) {
      return {
        title: PROGRESS_STATUS.POOR,
        subtitle: rate.poor,
        class: 'red'
      };
    }

    const result = thresholds.find(({value}) => number > value);

    if (result) {
      const isYellow = [PROGRESS_STATUS.NEEDS_WORK, PROGRESS_STATUS.OKAY].includes(result.status)
      return {
        title: result.status,
        subtitle: result.value,
        class: isYellow ? 'yellow' : 'green'
      };
    }

    return {
      title: PROGRESS_STATUS.POOR,
      subtitle: rate.poor,
      class: ''
    };
  }
}

class ProgressComponent extends EmailHealthComponent {
  render() {
    const {title, heading, tagTitle, tagSubtitle, tagClass} = this.props
    return (
      <li className='stw-list-item'>
        <div className='stw-column'>
          <h2 className='stw-title'>{title}</h2>
          <h3 className='stw-heading'>{heading}</h3>
          <div className={`stw-tag-wrapper ${tagClass}`}>
            <h5 className='stw-tag-title'>{tagTitle}</h5>
            <h6 className='stw-tag-subtitle'>Benchmark: {tagSubtitle}</h6>
          </div>
        </div>
      </li>
    )
  }
}

export default ProgressComponent
export const AnalyticsOpensClicksComponent: ConnectedComponentClass<ComponentType<OpensClicksClass>, Fields> = GenericRedux.registerNewComponent(
  OpensClicksClass, 'analytics_email_health_report', {})
